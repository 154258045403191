import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';
import { ImportExport } from '@material-ui/icons';
import { Tooltip } from '@mui/material';
import { formatCreatedAt } from '../../components/FormatPrice';

const ExportData = ({ filterObject, showSearch}) => {
  const fetchContext = useContext(FetchContext);

  function fetchConvertedDate(dateValue, timezone) {
    const dt = DateTime.fromISO(dateValue.replace(' ', 'T'), { zone: 'UTC' });

    const dtConverted = dt.setZone(timezone);
    return dtConverted.toLocaleString(DateTime.DATE_SHORT) + ' ' + dtConverted.toLocaleString(DateTime.TIME_SIMPLE);
  }

  const asyncFnComputeData = async () => {
    const dataArr = [];
    await fetchContext.authAxios.post(
      `get-filter-charging-stations`,
      {
        // "site_id": filterObject.site_id,
        // "site_group_id": filterObject.site_group_id,
        // "ocpp_cbid": filterObject.ocpp_cbid,
        // "payment_interface": filterObject.payment_interface,
        // "connector_status": filterObject.connector_status,
        // "manufacturer_id": filterObject.manufacturer_id,
        // "search_keyword": filterObject.search_keyword,
        // "export": true
        "site_id": (filterObject.site_id !== "None") ? filterObject.site_id : null,
        "site_group_id": (filterObject.site_group_id !== "None") ? filterObject.site_group_id : null,
        "ocpp_cbid": filterObject.ocpp_cbid,
        "payment_interface": filterObject.payment_interface,
        "connector_status": (filterObject.connector_status !== "None") ? filterObject.connector_status : null,
        "manufacturer_id": (filterObject.manufacturer_id !== "None") ? filterObject.manufacturer_id : null,
        "search_keyword": filterObject.search_keyword,
        "charger_type": filterObject.charger_type,
        "is_search": (!showSearch),
        "export": true
      }
    ).then(({ data }) => {
      const pdata = data.data;

      if (pdata) {
        for (let item of pdata) {
          //dataObj.sessionID = item.id;  
          let obj = {
            chargingStation: item.name,
            ocppCbid: item.ocpp_cbid,
            site: item.site_name ? item.site_name : '', // Check if site is defined before accessing its name property
            site_group: item.site_group_name,
            charger_type: item.charger_type,
            charger_output: item.charger_output,
            // installed_at: item.installed_at ? fetchConvertedDate(item.installed_at, item.timezone) : '',
            // commissioned_at: item.commissioned_at ? fetchConvertedDate(item.commissioned_at, item.timezone) : '',
            installed_at: item.installed_at ? formatCreatedAt(item.installed_at):'',
            commissioned_at: item.commissioned_at ? formatCreatedAt(item.commissioned_at):"",
            last_boot_at: item.last_boot_at ? formatCreatedAt(item.last_boot_at):"",
            version: item.version ? item.version:"",
            owner: item.owner,
            // manufacturer: item.manufacturer.name,
            serial_number: item.serial_number ? item.serial_number:""
          };
          dataArr.push(obj);
        }
      }
    })
    return Promise.resolve(dataArr);
  };

  const columns = [
    { 'id': 'chargingStation', 'displayName': 'Charging Station' },
    { 'id': 'ocppCbid', 'displayName': 'OCPP CBID' },
    { 'id': 'site', 'displayName': 'Site Name' },
    { 'id': 'site_group', 'displayName': 'Site Account' },
    { 'id': 'charger_type', 'displayName': 'Charger Type' },
    { 'id': 'charger_output', 'displayName': 'Charger Output' },
    { 'id': 'installed_at', 'displayName': 'Installed At' },
    { 'id': 'commissioned_at', 'displayName': 'Comissioned At' },
    { 'id': 'last_boot_at', 'displayName': 'LastBoot At' },
    { 'id': 'version', 'displayName': 'FW Version' },

     { 'id': 'owner', 'displayName': 'Owner' },
    // { 'id': 'manufacturer', 'displayName': 'Manufacturer' },
    { 'id': 'serial_number', 'displayName': 'Serial Number' },
  ];



  return (
    <>
      <CsvDownloader
        filename="ChargingStations"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}>
        <Tooltip title="Export / Download">
          <ImportExport />
        </Tooltip>
      </CsvDownloader>
    </>
  );

};

export default ExportData;