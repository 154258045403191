import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useContext, useEffect, useState } from "react";
import ConfirmDialog from "../../components/ConfirmDialog";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Popup from "../../components/Popup";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import SiteForm from "./SiteForm";
//import { Paper, Table, TableBody, TableCell, TableContainer, Toolbar, Typography, TableHead, TableRow } from '@mui/material';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ExpandTable } from "../../components/controls/ExpandTable";
import Filter from "./Filter";
import ExportData from "./ExportData";
import countries from "i18n-iso-countries";
import { useParams, useHistory } from "react-router-dom";

import {
  BrowserRouter as Router,
  //HashRouter,
  Link,
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  link: {
    color: "black !important", // Set the color to red and use !important
    textDecoration: "none", // Remove underline from the link
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    // position: 'absolute',
    // right: '10px'
  },
  searchIcon: {
    marginTop: "25px",
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  // pageTitle: {
  //   color: theme.palette.secondary.main,
  //   position: "absolute",
  //   left: "10px",
  //   "& .MuiTypography-subtitle2": {
  //     opacity: "0.6",
  //   },
  // },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    //position: 'absolute',
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  headerContainer: {
    // [theme.breakpoints.between("sm", "md")]: {
    //   padding: "18px 0",
    // },
    alignItems: "center",
    padding: "18px 0",
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
    },
  },
  exportIcon: {
    //position: 'absolute',
    marginTop: "25px",
    //right: '200px',
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
}));

const Sites = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showSearch, setShowSearch] = useState(true);
  const { siteName } = useParams();

  const headCells = [
    { id: "", label: "" },
    { id: "name", label: "Site Name" },
    { id: "address", label: "Address" },
    { id: "PricePlanAC", label: "Price Plan AC" },
    { id: "PricePlanDC", label: "Price Plan DC" },
    { id: "siteGroup", label: "Site Account" },
    { id: "timezone", label: "Timezone" },
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    is_search: !showSearch,
    export: false,
  });

  const filterAction = async (fetchContext, page, filterObject) => {
    fetchContext.authAxios
      .post(`filter-sites?page=${page}`, filterObject)
      .then(({ data }) => {
        setRecords(data.data.data);
        if (data.data.total && data.data.total > 20) {
          let count = Math.ceil(data.data.total / 20);
          setCounts(count);
        } else {
          setCounts(1);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    filterAction(fetchContext, page, values);
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        `/filter-sites?page=${page}`,
        { export: false, search_keyword: siteName }
      );
      setRecords(data.data.data);
      setTotalRecord(data.data.total);
      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    if (!showSearch) {
      filterAction(fetchContext, page, filterObject);
    } else {
      getAll(fetchContext, page);
    }
  }, [fetchContext, page]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const handleSubmit = async (values) => {
    // try {
    //   await fetchContext.authAxios.post('/sites', values);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }

    let payload;

    if (values?.country) {
      let finalCountry = values?.country;
      payload = {
        ...values,
        country: countries.alpha2ToAlpha3(finalCountry),
      };
    } else {
      payload = values;
    }

    fetchContext.authAxios
      .post("/sites", payload)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Site can't be created as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Site can't be created as: ${err}`,
          type: "error",
        });
      });
  };

  const handleEdit = (id, values) => {
    // try {
    //   await fetchContext.authAxios.put(`/sites/${id}`, values);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }

    let payload;

    if (values?.country) {
      let finalCountry = values?.country;
      payload = {
        ...values,
        country: countries.alpha2ToAlpha3(finalCountry),
      };
    } else {
      payload = values;
    }

    fetchContext.authAxios
      .put(`/sites/${id}`, payload)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Site can't be edited as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Site can't be edited as: ${err}`,
          type: "error",
        });
      });
  };

  const handleDelete = (id) => {
    // try {
    //   await fetchContext.authAxios.delete(`/sites/${id}`);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }
    fetchContext.authAxios
      .delete(`/sites/${id}`)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          if (data.data) {
            setNotify({
              isOpen: true,
              message: `Site can't be deleted as: ${data.data}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Site can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1);
    setFilterObject({
      search_keyword: null,
      is_search: !showSearch,
      export: false,
    });
  };

  const addOrEdit = (site, resetForm) => {
    if (site.id === 0 || site.id === undefined) handleSubmit(site);
    else handleEdit(site.id, site);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // setNotify({
    //     isOpen: true,
    //     message: 'Submitted Successfully',
    //     type: 'success'
    // })
  };

  const openInPopup = (item) => {
    if (item?.country) {
      setRecordForEdit({
        ...item,
        country: countries.alpha3ToAlpha2(item?.country),
      });
    } else {
      setRecordForEdit(item);
    }
    setOpenPopup(true);
  };

  const closeForm = () => {
    setOpenPopup(false);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    handleDelete(id);

    // setNotify({
    //     isOpen: true,
    //     message: 'Deleted Successfully',
    //     type: 'error'
    // })
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
        { fetchContext.isAdmin &&<div className={classes.searchIcon} onClick={onShowHideSearch}>
            {showSearch ? <Tooltip title="Search"><SearchIcon /></Tooltip> : <Tooltip title="Remove Search"><SearchOffIcon /></Tooltip>}
          </div>}
          <div className={classes.exportIcon}>
          <ExportData filterObject={filterObject} />
          </div>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">Sites</Typography>
          </div>
          {!fetchContext.isView &&
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
            />
          }
        </Toolbar> */}
        <div className={`row ${classes.headerContainer}`}>
          <div className="col">
            <div className={classes.pageTitle}>
              <Typography variant="h5" component="div">
                Sites
              </Typography>
            </div>
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            <div className="col" style={{ display: "inline-block" }}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            <div className="col" style={{ display: "inline-block" }}>
              <div className={classes.exportIcon}>
                <ExportData filterObject={filterObject} />
              </div>
            </div>
            <div className="col" style={{ display: "inline-block" }}>
              {!fetchContext.isView && (
                <Controls.Button
                  style={{ color: "#a31112", border: "1px solid #a31112" }}
                  text="Add New"
                  isPaddingTop={false}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!showSearch && (
          <Filter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearch}
          />
        )}
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item, index) => (
                <ExpandTable
                  expandedContent={
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Account Number</TableCell>
                            <TableCell>Meter Number</TableCell>
                            <TableCell>Utility Transformer Capacity</TableCell>
                            <TableCell>Utility Service Capacity</TableCell>
                            <TableCell>Coordinates</TableCell>
                            <TableCell>Postal Code</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{item.account_number}</TableCell>
                            <TableCell>{item.meter_number}</TableCell>
                            <TableCell>
                              {item.utility_transformer_capacity}
                            </TableCell>
                            <TableCell>
                              {item.utility_service_capacity}
                            </TableCell>
                            <TableCell>{`Lat: ${
                              JSON.parse(item.coordinates).latitude
                            }, Long: ${
                              JSON.parse(item.coordinates).longitude
                            }`}</TableCell>
                            <TableCell>{item.postal_code}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  key={index}
                >
                  <TableCell>
                    <Link
                      to={`/charging-stations/${encodeURIComponent(item.id)}`}
                      className={classes.link}
                    >
                      {item.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {item.address}
                    {item?.city ? `, ${item.city}` : ""}
                    <br />
                    {`${item.state}, ${item?.postal_code}, ${item.country}`}
                  </TableCell>
                  <TableCell>
                    {item.ac_price_plan ? item.ac_price_plan.name : "--"}
                  </TableCell>
                  <TableCell>
                    {item.price_plan ? item.price_plan.name : "--"}
                  </TableCell>
                  <TableCell>{item.site_group.name}</TableCell>
                  <TableCell>{item.timezone}</TableCell>
                  {!fetchContext.isView && (
                    <TableCell class="text-nowrap">
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete this record?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(item.id);
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </ExpandTable>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Popup title="Site" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <SiteForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          closeForm={closeForm}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Sites;
