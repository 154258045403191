import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//import { DateTime } from 'luxon';

const DatePicker = (props) => {

    const { name, label, value, minDate, maxDate, disabled = false, onChange, required } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })
    //const formateType = 'yyyy-MM-dd hh:mm:ss';
    // const formatedValue = (dateStr) => {
    //     //return format(new Date(dateStr), 'yyyy-MM-dd hh:mm:ss');
    //     return DateTime.fromISO(dateStr).toFormat(formateType);
    // }
    
    
    //https://material-ui-pickers.dev/demo/datepicker
    //     <DatePicker
    //     views={["year", "month"]}
    //     label="Year and Month"
    //     helperText="With min and max"
    //     minDate={new Date("2018-03-01")}
    //     maxDate={new Date("2018-06-01")}
    //     value={selectedDate}
    //     onChange={handleDateChange}
    //   />
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker disableToolbar variant="inline" //inputVariant="outlined"
                minDate={minDate}
                maxDate={maxDate}
                label={label}
                //format="MMM/dd/yyyy"
                format="MM-dd-yyyy"
                required={required}
                name={name}
                autoOk={true}
                value={value}
                disabled={disabled}
                onChange={date =>onChange(convertToDefEventPara(name,date))}
                //onChange={onChange}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker;
