import React from 'react'
//import { makeStyles } from '@material-ui/core';
//import { Alert } from '@material-ui/lab';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

// const useStyles = makeStyles(theme => ({
//   root: {
//     top: theme.spacing(9),
//     //width: '700px',
//   }
// }))

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = (props) => {

  const { notify, setNotify } = props;
  //const classes = useStyles()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false
    })
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar        
        open={notify.isOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}      
        onClose={handleClose}>
        <Alert
            sx={{ width: '700px' }}
            severity={notify.type}
            onClose={handleClose}>
            {notify.message}
        </Alert>
      </Snackbar>
    </Stack>

  )
}

export default Notification;
