import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import RfidForm from "./RfidForm";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, Typography, Tooltip } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../components/Popup";
import Loader from "../../components/Loader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Filter from "./Filter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import OptionModal from "./OptionModal";
import svgIcon from "../../assets/images/more-icon.svg";
import { useIsDesktop } from "../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    // padding: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: theme.spacing(1),
      paddingTop:theme.spacing(1),
      paddingBottom:theme.spacing(1),
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: theme.spacing(1),
    },
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    // position: "absolute",
    // right: "10px",
  },
  filterIcon: {
    // position: "absolute",
    // marginTop: "25px",
    right: "120px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    // position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  headerContainer: {
    // [theme.breakpoints.between("sm", "md")]: {
    //   padding: "18px 0",
    // },
    padding: "18px 0",
    alignItems: "center",
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
    },
  },
  exportIcon: {
    //position: 'absolute',
    marginTop: "25px",
    //right: '200px',
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  searchIcon: {
    marginTop: "25px",
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

const RfidDetails = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [showSearch, setShowSearch] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [dropdownChargingValues, setDropdownChargingValues] = useState([]);
  const [remainingData, setRemainingData] = useState({ type: "", data: [] });
  const [dropdownPopup, setDropdownPopup] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);

  const [filterObject, setFilterObject] = useState({
    dname: null,
    authentication_tag: null,
    authentication_type: null,
    site: null,
    charging_station: null,
  });

  const headCells = [
    { id: "name", label: "Name" },
    { id: "rfid", label: "Authentication Tag" },
    { id: "rfid", label: "Authentication Type" },
    { id: "site", label: "Site" },
    { id: "charging_station", label: "Charging Station" },
    //{ id: 'actions', label: '', disableSorting: true }
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page) => {
    try {
      const { data } = await fetchContext.authAxios.get(`/rfid?page=${page}`);
      setRecords(data.data.data);
      setTotalRecord(data.data.total);
      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    if (!showFilter || !showSearch) {
      getAllForFilter(fetchContext, page, filterObject);
    } else {
      getAll(fetchContext, page);
    }
  }, [fetchContext, page, filterObject]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const handleSubmit = (values) => {
    // try {
    //   await fetchContext.authAxios.post('/rfid', values);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }

    let payload = {
      ...values,
      site_id: values?.site_id?.length > 0 ? values?.site_id?.map((site) => site?.value) : [],
      charging_station_id: values?.charging_station_id?.length > 0 ? values?.charging_station_id?.map((csite) => csite?.value) : [],
    };

    fetchContext.authAxios
      .post("/rfid", payload)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors) {
            let msg = "";
            if (data.errors.name) {
              msg = data.errors.name[0];
            }
            if (data.errors.token) {
              msg = data.errors.token[0];
            }
            setNotify({
              isOpen: true,
              message: `Authenticate Tag can't be created as: ${msg}`,
              type: "error",
            });
          }
          if (data?.site_id?.length > 0) {
            let result = values.site_id
              .filter((item) => data.site_id.includes(item.id))
              .map((x) => x.name)
              .join(", ");
            setNotify({
              isOpen: true,
              message: `This site name is already exist: ${result}`,
              type: "error",
            });
          }
          if (data?.charging_station_id?.length > 0) {
            let result = values.charging_station_id
              .filter((item) => data.charging_station_id.includes(item.id))
              .map((x) => x.name)
              .join(", ");
            setNotify({
              isOpen: true,
              message: `This charging station is already exist: ${result}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Authenticate Tag can't be created as: ${err}`,
          type: "error",
        });
      });
  };

  const handleEdit = (id, values) => {
    // try {
    //   await fetchContext.authAxios.put(`/rfid/${id}`, values);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }

    let payload = {
      id: values?.id,
      name: values?.name,
      token: values?.token,
      type: values?.type,
      charging_station_id: values?.charging_station_id?.length > 0 ? values?.charging_station_id?.map((it) => it?.value) : [],
      site_id: values?.site_id?.length > 0 ? values?.site_id?.map((it) => it?.value) : [],
    };

    fetchContext.authAxios
      .put(`/rfid/${id}`, payload)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors) {
            let msg = "";
            if (data.errors.name) {
              msg = data.errors.name[0];
            }
            if (data.errors.token) {
              msg = data.errors.token[0];
            }
            setNotify({
              isOpen: true,
              message: `Authenticate Tag can't be edited as: ${msg}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Authenticate Tag can't be edited as: ${err}`,
          type: "error",
        });
      });
  };

  const handleDelete = (id) => {
    // try {
    //   await fetchContext.authAxios.delete(`/rfid/${id}`);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    // }

    fetchContext.authAxios
      .delete(`/rfid/${id}`)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          if (data.data) {
            setNotify({
              isOpen: true,
              message: `Authenticate Tag can't be deleted as: ${data.data}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Authenticate Tag can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };

  const addOrEdit = (siteGroup, resetForm) => {
    if (siteGroup.id === 0) handleSubmit(siteGroup);
    else handleEdit(siteGroup.id, siteGroup);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  const closeForm = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-charging-stations");
        setDropdownChargingValues(data.data);
        setLoaded(true);
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllChargingStations(fetchContext);
  }, [fetchContext]);

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-sites");
        setDropdownValues(data.data);
        setLoaded(true);
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllChargingStations(fetchContext);
  }, [fetchContext]);

  const openInPopup = (item) => {
    // let result = dropdownValues.filter((obj) => {
    //   return item?.token_data.some((item) => item.site_id === obj.id);
    // });
    if (dropdownValues?.length > 0 && !item?.charging_stations) {
      let finalData = [];
      let result1 = item?.token_data?.map((item) => dropdownValues?.find((obj) => obj.id === item.site_id));
      let filtredData = result1?.filter((x) => x?.id);
      if (Boolean(result1?.filter((x) => x?.id).length)) {
        finalData = filtredData.map((datas) => {
          return { value: datas?.id, label: datas?.name };
        });
      } else {
        finalData = [];
      }
      let updatedData = { ...item, sites: finalData };
      setRecordForEdit(updatedData);
    } else if (dropdownChargingValues?.length > 0 && !item?.site_id) {
      let finalData = [];
      let result1 = item?.token_data?.map((item) => dropdownChargingValues?.find((obj) => obj.id === item.charging_station_id));
      let filtredData = result1?.filter((x) => x?.id);
      if (Boolean(result1?.filter((x) => x?.id).length)) {
        finalData = filtredData.map((datas) => {
          return { value: datas?.id, label: datas?.name };
        });
      } else {
        finalData = [];
      }
      let updatedData = { ...item, charging_stations: finalData };
      setRecordForEdit(updatedData);
    }
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    handleDelete(id);
    // setNotify({
    //     isOpen: true,
    //     message: 'Deleted Successfully',
    //     type: 'error'
    // })
  };

  const filterAction = async (fetchContext, page, filterObject) => {
    // const { data } = await fetchContext.authAxios.get(`/rfid?page=${page}`);

    let payload = {
      dname: filterObject?.search_keyword,
      authentication_tag: null,
      authentication_type: null,
      site: null,
      chargingStation: null,
    };

    fetchContext.authAxios
      .post(`/get-rfid-by-param?page=${page}`, payload)
      .then(({ data }) => {
        setRecords(data.data.data);
        setTotalRecord(data.data.total);
        if (data.data.total && data.data.total > 20) {
          let count = Math.ceil(data.data.total / 20);
          setCounts(count);
        } else {
          setCounts(1);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    setLoaded(false);
    setFilterObject(values);
    // getAllForFilter(fetchContext, page, values);
  };

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setShowFilter(true);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1);
    setFilterObject({
      dname: null,
      authentication_tag: null,
      authentication_type: null,
      site: null,
      charging_station: null,
    });
  };

  const onFilter = (filterValues) => {
    let editedFiltervalues = {
      authentication_tag: filterValues?.authentication_tag,
      //authentication_type: filterValues?.authentication_type,
      authentication_type: filterValues?.authentication_type !== "None" ? filterValues?.authentication_type : null,
      site: filterValues?.site ? filterValues?.site?.id : filterValues?.site,
      charging_station: filterValues?.charging_station ? filterValues?.charging_station?.id : filterValues?.charging_station,
      name: filterValues?.name ? filterValues?.name : null,
      search_keyword: filterValues?.search_keyword,
    };
    onFilterSubmit(editedFiltervalues);
    setOpenPopup(false);
  };

  const getAllForFilter = (fetchContext, page, filterObject) => {
    fetchContext.authAxios
      .post(`get-rfid-by-param?page=${page}`, filterObject)
      .then(({ data }) => {
        setRecords(data.data.data);
        setTotalRecord(data.data.total);
        if (data.data.total && data.data.total > 20) {
          let count = Math.ceil(data.data.total / 20);
          setCounts(count);
        } else {
          setCounts(1);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // useEffect(() => {
  //   getAllForFilter(fetchContext, page, filterObject);
  // }, [fetchContext]);

  const onShowHideFilter = () => {
    setShowFilter(!showFilter);
    setShowSearch(true);
    if (!showFilter || !showSearch) {
      setLoaded(false);
      setFilterObject({
        dname: null,
        authentication_tag: null,
        authentication_type: null,
        site: null,
        charging_station: null,
      });
    }
  };

  const handleOpenPopup = (item, type) => {
    setDropdownPopup(true);
    // setRemainingData({ type: type, date: token_data });
    if (dropdownValues?.length > 0 && !item?.charging_stations && item?.site_id) {
      let result1 = item?.token_data?.map((item) => dropdownValues?.find((obj) => obj?.id === item?.site_id));
      let filtredData = result1?.filter((x) => x?.id);
      if (Boolean(result1?.filter((x) => x?.id).length)) {
        result1 = filtredData;
      } else {
        result1 = [];
      }
      let updatedData = { ...item, sites: result1 };
      // setRecordForEdit(updatedData);
      setRemainingData({ type: type, date: updatedData?.sites });
    } else if (dropdownChargingValues?.length > 0 && !item?.site_id && item?.charging_stations) {
      let result1 = item?.token_data?.map((item) => dropdownChargingValues?.find((obj) => obj?.id === item?.charging_station_id));
      let filtredData = result1?.filter((x) => x?.id);
      if (Boolean(result1?.filter((x) => x?.id).length)) {
        result1 = filtredData;
      } else {
        result1 = [];
      }
      let updatedData = { ...item, charging_stations: result1 };
      // setRecordForEdit(updatedData);
      setRemainingData({ type: type, date: updatedData?.charging_stations });
    }
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          {fetchContext.isAdmin && (
            <div className={classes.searchIcon} onClick={onShowHideSearch}>
              {showSearch ? (
                <Tooltip title="Search">
                  <SearchIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Remove Search">
                  <SearchOffIcon />
                </Tooltip>
              )}
            </div>
          )}
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {showFilter ? (
              <Tooltip title="Filter">
                <FilterAltIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltOffIcon />
              </Tooltip>
            )}
          </div>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Authenticate
            </Typography>
          </div>
          {!fetchContext.isView && (
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setShowFilter(true);
              }}
            />
          )}
        </Toolbar> */}
        <div className={`row ${classes.headerContainer}`}>
          <div className="col">
            <div className={classes.pageTitle}>
              {/* <Typography variant="h5" component="div"> */}
              Authenticate
              {/* </Typography> */}
            </div>
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            <div className="col" style={{ display: "inline-block" }}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            <div className="col" style={{ display: "inline-block" }} onClick={onShowHideFilter}>
              <div className={classes.filterIcon}>
                {showFilter ? (
                  <Tooltip title="Filter">
                    <FilterAltIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Remove Filter">
                    <FilterAltOffIcon />
                  </Tooltip>
                )}
              </div>
            </div>
            {/* <div className="col" style={{ display: "inline-block" }}>
              <div className={classes.exportIcon}>
                <ExportData filterObject={filterObject} />
              </div>
            </div> */}
            <div className="col" style={{ display: "inline-block" }}>
              {!fetchContext.isView && (
                <Controls.Button
                  style={{ color: "#a31112", border: "1px solid #a31112" }}
                  text="Add New"
                  isPaddingTop={false}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!showSearch && <Filter showSearch={showSearch} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideSearch} />}
        {!showFilter && <Filter showSearch={showSearch} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideFilter} />}

        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item) => {
                let string = item?.sites?.length > 0 && item?.sites?.map((site) => site?.name).join(", ");
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.token}</TableCell>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>
                      {/* {item.sites?.length > 0 ? (string?.length > 5 ? string.substring(0, 5) + "..." : string) : item.sites ? item.sites.name : "--"} */}
                      {/* {item.sites ? item.sites.name : "--"} {"  "} */}
                      {/* <span onClick={() => handleOpenPopup(item, "Site")}>{item.sites ? <img style={{ height: "14px", width: "14px" }} src={svgIcon} /> : "--"}</span> */}
                      <span>{item.sites ? item.sites.name : "--"} </span>
                      <span onClick={() => handleOpenPopup(item, "Site")} style={{ paddingLeft: "4px", paddingBottom: "6px" }}>
                        {item.sites && <img style={{ height: "14px", width: "14px" }} src={svgIcon} />}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>{item.charging_stations ? item.charging_stations.name : "--"} </span>
                      <span onClick={() => handleOpenPopup(item, "Charging Station")} style={{ paddingLeft: "4px", paddingBottom: "6px" }}>
                        {item.charging_stations && <img style={{ height: "14px", width: "14px" }} src={svgIcon} />}
                      </span>
                    </TableCell>
                    {(fetchContext.isAdmin || !item.name.replace(/\s/g, "").toLowerCase().includes("redesupport")) && (
                      <TableCell class="text-nowrap">
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            openInPopup(item);
                          }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: "Are you sure to delete this record?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => {
                                onDelete(item.token);
                              },
                            });
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </Controls.ActionButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Popup title="Authenticate" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <RfidForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} closeForm={closeForm} />
      </Popup>
      <Popup title={remainingData?.type} openPopup={dropdownPopup} setOpenPopup={setDropdownPopup}>
        {/* <RfidForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} closeForm={closeForm} /> */}
        <OptionModal remainingData={remainingData} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default RfidDetails;
