import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import { FetchContext } from "../../context/FetchContext";
import { useIsDesktop } from "../../context/DisplayContext";

const ChargerGroupForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm } = props;
  const fetchContext = useContext(FetchContext);
  const [optionValues, setOptionValues] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const getOptionLabel = (option) => `${option.label}`;
  const getOptionDisabled = (option) => option.value === 1;
  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const { isDesktop } = useIsDesktop();
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(optionValues);
    } else {
      handleClearOptions();
    }
  };

  const initialFValues = {
    id: 0,
    name: "",
    charging_station_ids: [],
  };

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-charging-stations");
        const options = [];
        for (const item of data.data) {
          const optObj = { value: item.id, label: `${item.name} (${item?.ocpp_cbid})` };
          options.push(optObj);
        }
        setOptionValues(options);
        if (recordForEdit != null) {
          let chargingStationIds = recordForEdit.charging_stations;
          //let chargingStationIds = [6, 7];
          let defaultOptions = options.filter((item) => chargingStationIds.some((chstid) => item.value === chstid.id));
          setSelectedOptions(defaultOptions);
        }
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllChargingStations(fetchContext);
  }, [fetchContext, recordForEdit]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues) temp.name = /^[a-zA-Z\d\s]*$/.test(fieldValues.name) ? "" : "Please enter correct charger group name";
    if ("description" in fieldValues) temp.description = fieldValues.description ? "" : "Please provide Charger Group Description.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      //const selectedIds = [];
      Object.assign(values, { charging_station_ids: [] });
      selectedOptions.forEach((item) => {
        values.charging_station_ids.push(item.value);
      });
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);

  return (
    // <Form onSubmit={handleSubmit} style={{ flexGrow: 1, minWidth: "860px" }}>
    <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="name"
            label="Charger Group Name"
            required
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="description"
            label="Description"
            required
            value={values.description}
            onChange={handleInputChange}
            error={errors.description}
            //fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.MultiSelect
            items={optionValues}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            selectedValues={selectedOptions}
            label={"Select Charging Stations"}
            placeholder={"Placeholder for Charging Stations"}
            selectAllLabel="Select all"
            onToggleOption={handleToggleOption}
            onClearOptions={handleClearOptions}
            onSelectAll={handleSelectAll}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Cancel" color="default" onClick={closeForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ChargerGroupForm;
