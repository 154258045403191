import { ImportExport } from '@material-ui/icons';
import { Tooltip, CircularProgress, Snackbar } from '@mui/material'; // Import Snackbar for the toast notification
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';


const ExportData = ({ filterObject }) => {
  const fetchContext = useContext(FetchContext);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display

  function fetchConvertedDate(dateValue, timezone) {
    const dt = DateTime.fromISO(dateValue.replace(' ', 'T'), { zone: 'UTC' });

    const dtConverted = dt.setZone(timezone);
    return dtConverted.toLocaleString(DateTime.DATE_SHORT) + ' ' + dtConverted.toLocaleString(DateTime.TIME_SIMPLE);
  }
  const user = localStorage.getItem('user_id');
const userId = parseInt(user); // Convert user ID to an integer if necessary

  const asyncFnComputeData = async () => {
    const dataArr = [];
    setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    if (userId === 290 || userId === 315) {
      await fetchContext.authAxios.post(
        `/filter-transactions-complete-data`,
        {
          "session_type": filterObject.session_type,
          "site_id": filterObject.site_id,
          "ocpp_cbid": filterObject.ocpp_cbid,
          "max_energy": filterObject.max_energy,
          "transaction_status": filterObject.transaction_status,
          "site_group_id": filterObject.site_group_id,
          "min_energy": filterObject.min_energy,
          "session_id": filterObject.session_id,
          "from_date": filterObject.from_date ? DateTime.fromJSDate(filterObject.from_date).toFormat('dd-MM-yyyy') : filterObject.from_date,
          "to_date": filterObject.to_date ? DateTime.fromJSDate(filterObject.to_date).toFormat('dd-MM-yyyy') : filterObject.to_date,
          "start_type": filterObject?.start_type,
          "export": true,
          "charger_group_id": filterObject?.charger_group_id,
          "manufacturer_id":filterObject?.manufacturer_id
        }
      ).then(({ data }) => {
        const pdata = data.data;
        if (pdata) {
  
          for (let item of pdata) {
            let price_plan = item?.price_plan ? JSON.parse(item?.price_plan) : null
            //dataObj.sessionID = item.id;  
            let obj = {
              sessionID: item.id,
              chargingStation: item?.name,
              ocppCbid: item?.ocpp_cbid,
              site: item?.site_name,
              site_group: item?.site_group_name,
              connectorID: item?.sequence_number,
              type: `${item.type} ${item.type === "RFID" ? (item?.rfid_tag ? `(${item?.rfid_tag?.name})` : "") : (item.type === "REMOTE_TRANSACTION" ? (item?.party_name ? `(${item?.party_name})` : `(${item.start_type})`) : "")}`,
              status: item.status,
              amount: item?.amount > 0 ? item.amount.toFixed(2) : '0.00',
              // amount: item.type !== "FREE" && item.amount !== null && item.amount ? item.amount.toFixed(2) : "0.00",
              //energyDelivered: item.meter_end && item.meter_start ? ((item.meter_end - item.meter_start) / 1000).toFixed(2) : '',
              energyDelivered: item?.energy ? item?.energy : '',
              startedAt: item?.session_start ? fetchConvertedDate(item?.session_start, item?.timezone) : '',
              endedAt: item.session_end ? fetchConvertedDate(item?.session_end, item?.timezone) : '',
              duration: item?.session_end && item?.session_start ? DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat('hh:mm:ss') : '',
              pluggedIn: item?.plug_in ? fetchConvertedDate(item?.plug_in, item?.timezone) : '',
              pluggedOut: item?.plug_out ? fetchConvertedDate(item?.plug_out, item?.timezone) : '',
              //pluggedDuration: item.plug_in && item.plug_out ? DateTime.fromSQL(item.plug_out).diff(DateTime.fromSQL(item.plug_in)).toFormat('hh:mm:ss') : '',
              startSOC: item?.soc_start,
              charger_type: item?.charger_type ?item?.charger_type:'' ,
              endSOC: item?.soc_end,
              //paymentReference: '',
              // maxPower: item?.max_power > 0 ? parseInt(item.max_power) / 1000 : '',
              // averagePower: item.average_power,
              // maxVoltage: item.max_voltage,
              // averageVoltage: item.average_voltage,
              // petroleumDisplacement: item.meter_start !== null && item.meter_end !== null ? (((item.meter_end - item.meter_start) / 1000) / 6).toFixed(2) : '',
              // greenhouseGas: item.meter_start !== null && item.meter_end !== null ? (((item.meter_end - item.meter_start) / 1000) * 1.3165).toFixed(2) : '',
              fixedFee: price_plan ? price_plan?.fixed_fee : '',
              variableFee: price_plan?.variable_fee ? price_plan?.variable_fee :"",
              feeType: price_plan ? price_plan?.fee_type : "",
              parkingFee: price_plan?.parking_fee ? price_plan?.parking_fee :"",
              parkingFeeUnit: price_plan?.parking_fee_unit ? price_plan?.parking_fee_unit :"",
              bufferTime: price_plan?.buffer_time ? price_plan?.buffer_time :"",
              taxPercentage: item?.taxes?.length ? item.taxes[0].tax_percentage : '',
              // Session Fee,
  
            };
            dataArr.push(obj);
          }
          setLoading(false); // Set loading to false after fetching data
          setShowMessage(false); // Show message when starting download
  
        }
      })
    }
    else{
      await fetchContext.authAxios.post(
        `/filter-transactions`,
        {
          "session_type": filterObject.session_type,
          "site_id": filterObject.site_id,
          "ocpp_cbid": filterObject.ocpp_cbid,
          "max_energy": filterObject.max_energy,
          "transaction_status": filterObject.transaction_status,
          "site_group_id": filterObject.site_group_id,
          "min_energy": filterObject.min_energy,
          "session_id": filterObject.session_id,
          "from_date": filterObject.from_date ? DateTime.fromJSDate(filterObject.from_date).toFormat('dd-MM-yyyy') : filterObject.from_date,
          "to_date": filterObject.to_date ? DateTime.fromJSDate(filterObject.to_date).toFormat('dd-MM-yyyy') : filterObject.to_date,
          "start_type": filterObject?.start_type,
          "export": true,
          "charger_group_id": filterObject?.charger_group_id,
          "manufacturer_id":filterObject?.manufacturer_id
        }
      ).then(({ data }) => {
        const pdata = data.data;
        if (pdata) {
  
          for (let item of pdata) {
            let price_plan = item?.price_plan ? JSON.parse(item?.price_plan) : null
            //dataObj.sessionID = item.id;  
            let obj = {
              sessionID: item.id,
              chargingStation: item?.name,
              ocppCbid: item?.ocpp_cbid,
              site: item?.site_name,
              site_group: item?.site_group_name,
              connectorID: item?.sequence_number,
              type: `${item.type} ${item.type === "RFID" ? (item?.rfid_tag ? `(${item?.rfid_tag?.name})` : "") : (item.type === "REMOTE_TRANSACTION" ? (item?.party_name ? `(${item?.party_name})` : `(${item.start_type})`) : "")}`,
              status: item.status,
              amount: item?.amount > 0 ? item.amount.toFixed(2) : '0.00',
              // amount: item.type !== "FREE" && item.amount !== null && item.amount ? item.amount.toFixed(2) : "0.00",
              //energyDelivered: item.meter_end && item.meter_start ? ((item.meter_end - item.meter_start) / 1000).toFixed(2) : '',
              energyDelivered: item?.energy ? item?.energy : '',
              startedAt: item?.session_start ? fetchConvertedDate(item?.session_start, item?.timezone) : '',
              endedAt: item.session_end ? fetchConvertedDate(item?.session_end, item?.timezone) : '',
              duration: item?.session_end && item?.session_start ? DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat('hh:mm:ss') : '',
              pluggedIn: item?.plug_in ? fetchConvertedDate(item?.plug_in, item?.timezone) : '',
              pluggedOut: item?.plug_out ? fetchConvertedDate(item?.plug_out, item?.timezone) : '',
              //pluggedDuration: item.plug_in && item.plug_out ? DateTime.fromSQL(item.plug_out).diff(DateTime.fromSQL(item.plug_in)).toFormat('hh:mm:ss') : '',
              startSOC: item?.soc_start,
              charger_type: item?.charger_type ?item?.charger_type:'' ,
              endSOC: item?.soc_end,
              //paymentReference: '',
              // maxPower: item?.max_power > 0 ? parseInt(item.max_power) / 1000 : '',
              // averagePower: item.average_power,
              // maxVoltage: item.max_voltage,
              // averageVoltage: item.average_voltage,
              // petroleumDisplacement: item.meter_start !== null && item.meter_end !== null ? (((item.meter_end - item.meter_start) / 1000) / 6).toFixed(2) : '',
              // greenhouseGas: item.meter_start !== null && item.meter_end !== null ? (((item.meter_end - item.meter_start) / 1000) * 1.3165).toFixed(2) : '',
              // fixedFee: price_plan ? price_plan?.fixed_fee : '',
              // variableFee: item?.price_plan?.variable_fee,
              // feeType: price_plan ? price_plan?.fee_type : "",
              // parkingFee: item?.price_plan?.parking_fee,
              // parkingFeeUnit: item?.price_plan?.parking_fee_unit,
              // bufferTime: item?.price_plan?.buffer_time,
              // taxPercentage: item?.taxes?.length ? item.taxes[0].tax_percentage : '',
  
            };
            dataArr.push(obj);
          }
          setLoading(false); // Set loading to false after fetching data
          setShowMessage(false); // Show message when starting download
  
        }
      })
    }
    return Promise.resolve(dataArr);
  };

  const columns = [
    { 'id': 'sessionID', 'displayName': 'Session ID' },
    { 'id': 'chargingStation', 'displayName': 'Charging Station' },
    { 'id': 'ocppCbid', 'displayName': 'OCPP CBID' },
    { 'id': 'charger_type', 'displayName': 'Charger Type' },
    { 'id': 'site', 'displayName': 'Site Name' },
    { 'id': 'site_group', 'displayName': 'Site Account' },
    { 'id': 'connectorID', 'displayName': 'Connector ID' },
    { 'id': 'type', 'displayName': 'Type' },
    { 'id': 'status', 'displayName': 'Status' },
    { 'id': 'amount', 'displayName': 'Amount (USD)' },
    { 'id': 'energyDelivered', 'displayName': 'Energy Delivered (kWH)' },
    { 'id': 'startedAt', 'displayName': 'Started At' },
    { 'id': 'endedAt', 'displayName': 'Ended At' },
    { 'id': 'duration', 'displayName': 'Duration' },
    { 'id': 'pluggedIn', 'displayName': 'Plugged In' },
    { 'id': 'pluggedOut', 'displayName': 'Plugged Out' },
    //{ 'id': 'pluggedDuration', 'displayName': 'Plugged Duration' },
    { 'id': 'startSOC', 'displayName': 'Start SOC' },
    { 'id': 'endSOC', 'displayName': 'End SOC' },
    //{ 'id': 'paymentReference', 'displayName': 'Payment Reference' },
    // { 'id': 'maxPower', 'displayName': 'Max Power' },
    // { 'id': 'averagePower', 'displayName': 'Average Power' },
    // { 'id': 'maxVoltage', 'displayName': 'Max Voltage' },
    // { 'id': 'averageVoltage', 'displayName': 'Average Voltage' },
    // { 'id': 'petroleumDisplacement', 'displayName': 'Petroleum Displacement (US gal)' },
    // { 'id': 'greenhouseGas', 'displayName': 'Greenhouse Gas (kg)' },
    // { 'id': 'fixedFee', 'displayName': 'Session Fee' },
    // { 'id': 'variableFee', 'displayName': 'Variable Fee' },
    // { 'id': 'feeType', 'displayName': ' Variable Fee Type' },
    // { 'id': 'parkingFee', 'displayName': 'Parking Fee' },
    // { 'id': 'parkingFeeUnit', 'displayName': 'Parking Fee Unit' },
    // { 'id': 'bufferTime', 'displayName': 'Buffer Time' },
    // { 'id': 'taxPercentage', 'displayName': 'Tax Percentage' },
  ];
  const columnsSingleUser = [
    { 'id': 'sessionID', 'displayName': 'Session ID' },
    { 'id': 'chargingStation', 'displayName': 'Charging Station' },
    { 'id': 'ocppCbid', 'displayName': 'OCPP CBID' },
    { 'id': 'charger_type', 'displayName': 'Charger Type' },
    { 'id': 'site', 'displayName': 'Site Name' },
    { 'id': 'site_group', 'displayName': 'Site Account' },
    { 'id': 'connectorID', 'displayName': 'Connector ID' },
    { 'id': 'type', 'displayName': 'Type' },
    { 'id': 'status', 'displayName': 'Status' },
    { 'id': 'amount', 'displayName': 'Amount (USD)' },
    { 'id': 'energyDelivered', 'displayName': 'Energy Delivered (kWH)' },
    { 'id': 'startedAt', 'displayName': 'Started At' },
    { 'id': 'endedAt', 'displayName': 'Ended At' },
    { 'id': 'duration', 'displayName': 'Duration' },
    { 'id': 'pluggedIn', 'displayName': 'Plugged In' },
    { 'id': 'pluggedOut', 'displayName': 'Plugged Out' },
    //{ 'id': 'pluggedDuration', 'displayName': 'Plugged Duration' },
    { 'id': 'startSOC', 'displayName': 'Start SOC' },
    { 'id': 'endSOC', 'displayName': 'End SOC' },
    //{ 'id': 'paymentReference', 'displayName': 'Payment Reference' },
    // { 'id': 'maxPower', 'displayName': 'Max Power' },
    // { 'id': 'averagePower', 'displayName': 'Average Power' },
    // { 'id': 'maxVoltage', 'displayName': 'Max Voltage' },
    // { 'id': 'averageVoltage', 'displayName': 'Average Voltage' },
    // { 'id': 'petroleumDisplacement', 'displayName': 'Petroleum Displacement (US gal)' },
    // { 'id': 'greenhouseGas', 'displayName': 'Greenhouse Gas (kg)' },
    { 'id': 'fixedFee', 'displayName': 'Session Fee' },
    { 'id': 'variableFee', 'displayName': 'Variable Fee' },
    { 'id': 'feeType', 'displayName': ' Variable Fee Type' },
    { 'id': 'parkingFee', 'displayName': 'Parking Fee' },
    { 'id': 'parkingFeeUnit', 'displayName': 'Parking Fee Unit' },
    { 'id': 'bufferTime', 'displayName': 'Buffer Time' },
    { 'id': 'taxPercentage', 'displayName': 'Tax Percentage' },
  ];

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear() % 100;
  let finalDate = `${month}-${day}-${year}`;

  return (
    <>
      <CsvDownloader
        filename={`transactions(${finalDate})`}
        extension=".csv"
        separator=","
        columns={userId === 290 || userId === 315 ? columnsSingleUser : columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? <CircularProgress size={24} /> : <ImportExport />}
        </Tooltip>
      </CsvDownloader>
      
      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position at top center
        style={{ backgroundColor: 'red' }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );

};

export default ExportData;
