import React, { useContext } from "react";
//import { DateTime } from 'luxon';
import CsvDownloader from "react-csv-downloader";
import { ImportExport } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { FetchContext } from "../../../context/FetchContext";

const ExportSiteRevenueBreakdown = ({ filterDate, byMonth, selectedSiteId, data }) => {
  const fetchContext = useContext(FetchContext);

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  function formatNumberWithLeadingZero(number) {
    if (number >= 1 && number <= 9) {
      return `0${number}`;
    } else {
      return `${number}`;
    }
  }

  const asyncFnComputeData = async () => {
    const dataArr = [];
    // const finalPayload = {
    //   ...filterDate,
    //   export: true,
    //   date: byMonth,
    //   site_id: selectedSiteId,
    // };
    // await fetchContext.authAxios.post(`/get-revenue-by-site`, finalPayload).then(({ data }) => {
    //   const pdata = data.data.data;

    //   if (pdata) {
    //     for (let item of pdata) {
    //       //dataObj.sessionID = item.id;
    //       let obj = {
    //         site: item.site_name,
    //         amount: formatNumber(item.amount),
    //         energy: Number(item.energy).toFixed(2),
    //         duration: item?.timezone_duration ? (item.timezone_duration / 60 / item.total).toFixed(2) : "-",
    //         total: item.total,
    //         charging_station_name: item.charging_station_name,
    //         address: item.address,
    //         city: item.city,
    //         state: item.state,
    //         postal_code: item.postal_code,
    //         latitude: item.latitude,
    //         longitude: item.longitude,
    //         parking_amount: item?.parking_amount ? formatNumber(item?.parking_amount) : 0,
    //         tax_amount: item?.tax_amount ? formatNumber(item?.tax_amount) : 0,
    //         net_amount: formatNumber(item?.amount - item?.parking_amount - item?.tax_amount),
    //       };
    //       dataArr.push(obj);
    //     }
    //   }
    // });

    if (Boolean(data[selectedSiteId?.id]?.length)) {
      for (let index = 0; index < data[selectedSiteId?.id]?.length; index++) {
        let item = data[selectedSiteId?.id][index];
        let obj = {
          index: index + 1,
          date: `${formatNumberWithLeadingZero(item?.date?.c?.month)}/${formatNumberWithLeadingZero(item?.date?.c?.day)}/${item?.date?.c?.year}`,
          energy: Number(item.usage).toFixed(2),
          amount: `$${Number(item.amount).toFixed(2) || 0}`,
          site_payout: `$${item.site_payout}`,
        };
        dataArr.push(obj);
      }
    }
    return Promise.resolve(dataArr);
  };

  const columns = [
    { id: "index", displayName: "Site Name" },
    { id: "date", displayName: "Date" },
    { id: "energy", displayName: "Energy Delivered (KWH)" },
    // { id: "amount", displayName: "Amout" },
    { id: "site_payout", displayName: "Site Payout" },
  ];

  const revenueFileName = () => {
    if (filterDate?.range) {
      const from_date = filterDate?.from_date ? filterDate?.from_date : "";
      const from_date_parsedDate = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      const from_date_formattedDate = from_date_parsedDate.toFormat("d LLL yyyy");

      const to_date = filterDate?.to_date ? filterDate?.to_date : "";
      const to_date_parsedDate = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      const to_date_formattedDate = to_date_parsedDate.toFormat("d LLL yyyy");
      // return `Revenue Report (${from_date_formattedDate} to ${to_date_formattedDate})`
      if (from_date_formattedDate !== "Invalid DateTime" && to_date_formattedDate !== "Invalid DateTime") {
        return `${selectedSiteId?.name} Revnue Breakdown (${from_date_formattedDate} to ${to_date_formattedDate})`;
      } else {
        return `${selectedSiteId?.name} Revnue Breakdown`;
      }
    } else {
      const date = byMonth ? byMonth : "";
      const date_parsedDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      const date_formattedDate = date_parsedDate.toFormat("LLL yyyy");
      // return `Revenue Report (${date_formattedDate})`;
      if (date_formattedDate !== "Invalid DateTime") {
        return `${selectedSiteId?.name} Revnue Breakdown (${date_formattedDate})`;
      } else {
        return `${selectedSiteId?.name} Revnue Breakdown`;
      }
    }
  };

  return (
    <>
      <CsvDownloader
        // filename={"Site Revenue Breakdown"}
        filename={revenueFileName()}
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          <ImportExport style={{ fill: "#fff" }} />
        </Tooltip>
      </CsvDownloader>
    </>
  );
};

export default ExportSiteRevenueBreakdown;
