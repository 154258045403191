import React from "react";
//import { TextField } from '@mui/material';
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px", // Default font size
      transition: "0.2s ease-in-out",

    },
  },
  labelFocused: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
}));

const Input = (props) => {
  const { name, label, value = "", error = null, onChange, ...other } = props;
  const classes = useStyles();
  return (
    <>
      <TextField
        className="MuiTypography-root MuiTypography-h4 MuiTypography-displayInline"
        variant="standard"
        label={label}
        name={name}
        value={value}
        id="ddsad"
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
          },
        }}
        autoComplete="off" // Add this line to disable autocomplete
        //endAdornment={endAdornment}
        //fullWidth={fullWidth}
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
      />
    </>
  );
};

export default Input;
