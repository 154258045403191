import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';
import { ImportExport } from '@material-ui/icons';
import { Tooltip } from '@mui/material';

const ExportDataGroups = ({ siteId, csId, byMonth, isFilter, filterDate }) => {
  const fetchContext = useContext(FetchContext);

  function fetchConvertedDate(dateValue, timezone){
    const dt = DateTime.fromISO(dateValue.replace(' ', 'T'), { zone: 'UTC' });

    const dtConverted = dt.setZone(timezone);
    return dtConverted.toLocaleString(DateTime.DATE_SHORT) + ' ' + dtConverted.toLocaleString(DateTime.TIME_SIMPLE);
  }

  const asyncFnComputeData = async () => {
    const dataArr = [];
    const finalPayload =  isFilter ?  
    {
      "charger_group_id": siteId,
      "charging_station_id": csId,
      "date": null,
      'export': true,
      range: isFilter,
      to_date: filterDate?.to_date, 
      from_date: filterDate?.from_date
    } 
    : 
    {
      "charger_group_id": siteId,
      "charging_station_id": csId,
      "date": byMonth,
      'export': true,
      range: isFilter,
    }
    await fetchContext.authAxios.post(
      `/get-report-by-charger-group-id-charging-station-id`,finalPayload
    )
      .then(({ data }) => {
        const pdata = data.data;

        if (pdata) {
          for (let item of pdata) {
            //dataObj.sessionID = item.id;  
            let obj = {
              sessionID: item.id,
              chargingStation: item.charging_station_name,
              site: item.site_name,
              connectorID: item.sequence_number,
              type: `${item.type} ${item.type === "RFID" ? (item?.rfid_tag ? `(${item?.rfid_tag?.name})` : "" ): (item.type === "REMOTE_TRANSACTION" ? (item?.ocpi_session ? `(${item?.ocpi_session?.party_name})` :`(${item.start_type})`) : "")}`,
              status: item.status,
              energyDelivered: item.meter_end !== null && item.meter_start !== null ? ((item.meter_end - item.meter_start) / 1000).toFixed(2) : '',
              startedAt: item.session_start ? fetchConvertedDate(item.session_start, item.timezone) : '',
              endedAt: item.session_end ? fetchConvertedDate(item.session_end, item.timezone) : '',
              duration: item.session_end && item.session_start ? DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat('hh:mm:ss') : '',
              pluggedIn: item.plug_in ? fetchConvertedDate(item.plug_in, item.timezone) : '',
              pluggedOut: item.plug_out ? fetchConvertedDate(item.plug_out, item.timezone) : '',
              // pluggedDuration: item.plug_in && item.plug_out ? DateTime.fromSQL(item.plug_out).diff(DateTime.fromSQL(item.plug_in)).toFormat('hh:mm:ss') : '',
              startSOC: item.soc_start,
              endSOC: item.soc_end,
              //paymentReference: '',
              maxPower: item.max_power/1000,
              // averagePower: item.average_power,
              // maxVoltage: item.max_voltage,
              // averageVoltage: item.average_voltage,
              // petroleumDisplacement: item.meter_end !== null && item.meter_start !== null ? (((item.meter_end - item.meter_start) / 1000) / 6).toFixed(2) : '',
              // greenhouseGas: item.meter_end !== null && item.meter_start !== null ? (((item.meter_end - item.meter_start) / 1000) * 1.3165).toFixed(2) : '',
              amount: item.amount,
              total: item.total,
              address: item.address?.replace(/,/g, ''),
              city: item.city,   
              state: item.state,   
              postal_code: item.postal_code,
              latitude: item.latitude,
              longitude: item.longitude,
            };
            dataArr.push(obj);
          }
        }
      })
    return Promise.resolve(dataArr);
  };

  const columns = [
    { 'id': 'sessionID', 'displayName': 'Session ID' },
    { 'id': 'amount', 'displayName': 'Amount (USD)' },
    { 'id': 'chargingStation', 'displayName': 'Charging Station' },
    { 'id': 'site', 'displayName': 'Site Name' },
    { 'id': 'connectorID', 'displayName': 'Connector ID' },
    { 'id': 'type', 'displayName': 'Type' },
    { 'id': 'status', 'displayName': 'Status' },
    { 'id': 'energyDelivered', 'displayName': 'Energy Delivered (KWH)' },
    { 'id': 'startedAt', 'displayName': 'Started At' },
    { 'id': 'endedAt', 'displayName': 'Ended At' },
    { 'id': 'duration', 'displayName': 'Duration' },
    { 'id': 'pluggedIn', 'displayName': 'Plugged In' },
    { 'id': 'pluggedOut', 'displayName': 'Plugged Out' },
    // { 'id': 'pluggedDuration', 'displayName': 'Plugged Duration' },
    { 'id': 'startSOC', 'displayName': 'Start SOC' },
    { 'id': 'endSOC', 'displayName': 'End SOC' },
    //{ 'id': 'paymentReference', 'displayName': 'Payment Reference' },
    { 'id': 'maxPower', 'displayName': 'Max Power' },
    // { 'id': 'averagePower', 'displayName': 'Average Power' },
    // { 'id': 'maxVoltage', 'displayName': 'Max Voltage' },
    // { 'id': 'averageVoltage', 'displayName': 'Average Voltage' },
    // { 'id': 'petroleumDisplacement', 'displayName': 'Petroleum Displacement (US gal)' },
    // { 'id': 'greenhouseGas', 'displayName': 'Greenhouse Gas (kg)' },
    { 'id': 'address', 'displayName': 'Address' },
    { 'id': 'city', 'displayName': 'City' },
    { 'id': 'state', 'displayName': 'State' },
    { 'id': 'postal_code', 'displayName': 'Zipcode' },
    { 'id': 'latitude', 'displayName': 'Latitude' },
    { 'id': 'longitude', 'displayName': 'Longitude' },
  ];



  return (
    <>
      <CsvDownloader
        filename="ChargerGroupDetails"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}>
        <Tooltip title="Export / Download">
          <ImportExport />
        </Tooltip>
      </CsvDownloader>
    </>
  );

};

export default ExportDataGroups;
