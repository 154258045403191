import React, { useState } from 'react'
import { Table, TableHead, TableRow, TableCell, makeStyles, TableSortLabel } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(1),
        '& thead th': {
            fontWeight: '500',
            //color: theme.palette.string.main,
            color: '#fff',
            backgroundColor: theme.palette.secondary.light,
        },
        '& tbody td': {
            fontWeight: '400',
        },
        '& tbody tr': {
            borderBottom: 'solid 1px #E0E0E0',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
    actionButtonCell: {
        textAlign: 'right',
        paddingRight: '30px',
    },
    firstCol: {
        paddingRight: 0,
        width: '1px',
    },

}))

const useTable = ( headCells ) => {

    const classes = useStyles();

    //const pages = [10, 20, 30]
    // const [page, setPage] = useState(0)
    //const [rowsPerPage, setRowsPerPage] = useState(pages[page])
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()

    const TblContainer = props => (
        <div style={{overflowX: "auto", overflowY: "hidden"}}>
            <Table className={classes.table}>
                {props.children}
            </Table>
        </div>

    )

    const TblHead = props => {

        const handleSortRequest = cellId => {
            const isAsc = orderBy === cellId && order === "asc";
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId)
        }

        return (<TableHead>
            <TableRow>
                {
                    headCells.map((headCell, i) => (
                        <TableCell key={i}
                            className={ headCell.label === '' ? classes.firstCol : '' && headCell.label === 'Actions' ? classes.actionButtonCell : '' }
                            //className={ headCell.label === 'Actions' ? classes.actionButtonCell : ''}
                            sortDirection={orderBy === headCell.id ? order : false}
                            >
                            {/* {headCell.disableSorting ? headCell.label :
                                <TableSortLabel
                                style={{color: "white", fill: '#fff'}}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => { handleSortRequest(headCell.id) }}
                                >
                                {headCell.label}                                
                                </TableSortLabel> 
                            } */}
                            {headCell.label}                                

                        </TableCell>))
                }
            </TableRow>
        </TableHead>)
    }

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // }

    // const handleChangeRowsPerPage = event => {
    //     setRowsPerPage(parseInt(event.target.value, 10))
    //     setPage(0);
    // }

    // const TblPagination = () => (<TablePagination
    //     component="div"
    //     page={page}
    //     rowsPerPageOptions={pages}
    //     rowsPerPage={rowsPerPage}
    //     count={records.length}
    //     //onPageChange={handleChangePage}
    //     //onRowsPerPageChange={handleChangeRowsPerPage}
    // />)

    // const stableSort = (array, comparator) => {
    //     const stabilizedThis = array.map((el, index) => [el, index]);
    //     stabilizedThis.sort((a, b) => {
    //         const order = comparator(a[0], b[0]);
    //         if (order !== 0) return order;
    //         return a[1] - b[1];
    //     });
    //     return stabilizedThis.map((el) => el[0]);
    // }

    // const getComparator = (order, orderBy) => {
    //     return order === 'desc'
    //         ? (a, b) => descendingComparator(a, b, orderBy)
    //         : (a, b) => -descendingComparator(a, b, orderBy);
    // }

    // const descendingComparator = (a, b, orderBy) => {
    //     if (b[orderBy] < a[orderBy]) {
    //         return -1;
    //     }
    //     if (b[orderBy] > a[orderBy]) {
    //         return 1;
    //     }
    //     return 0;
    // }

    // const recordsAfterPagingAndSorting = () => {
    //     return stableSort(filterFn.fn(records), getComparator(order, orderBy))
    //         .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    // }

    return {
        TblContainer,
        TblHead,
        //TblPagination,
        //recordsAfterPagingAndSorting
    }
}

export default useTable;
