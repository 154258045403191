import React, { useContext, useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import UpTimeFilter from "./UpTimeFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
//import Controls from "../../components/controls/Controls";
// import AddIcon from '@material-ui/icons/Add';
// import Popup from "../../components/Popup";
// import { MoreHorizRounded } from '@material-ui/icons';
// import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../components/Loader";
// import ExportData from './ExportData';
// import Notification from "../../components/Notification";
// import ConfirmDialog from "../../components/ConfirmDialog";
import { Paper, TableBody, TableCell, TableHead, TableRow, Tooltip as TTip, Toolbar, Typography } from "@material-ui/core";
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
//import { ExpandTable } from '../../components/controls/ExpandTable';
import { makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import MonthRangeSelect from "./MonthRangeSelect";
import UpTimeReportExport from "./UpTimeReportExport";

//import { InfoOutlined  } from '@material-ui/icons';
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
//import SessionsPopUp from './SessionsPopUp';

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    padding: "18px 0",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
}));

function getMinutesInMonth(dateString) {
  let stringDate = dateString.split("-");

  let currentDate = new Date();
  let otherDate = new Date(`${stringDate[3]}-${stringDate[2]}-${stringDate[1]}`);
  let currentMonth = currentDate.getMonth() + 1;
  let otherMonth = otherDate.getMonth() + 1;

  if (currentMonth === otherMonth) {
    const inputDateTime = new Date(otherDate);
    const currentDateTime = new Date();
    const startOfMonth = new Date(inputDateTime.getFullYear(), inputDateTime.getMonth(), 1);
    const timeDifference = (currentDateTime - startOfMonth) / 60000;
    const totalMinutes = Math.floor(timeDifference);
    return totalMinutes;
  } else {
    let date = otherDate;
    let year = date.getFullYear();
    let month = date.getMonth();
    let lastDay = new Date(year, month + 1, 0).getDate();
    let totalMinutes = lastDay * 24 * 60;
    return totalMinutes;
  }
}

const UpTimeReport = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  const dateStr = DateTime.now();
  const selectedMonth = dateStr.toFormat(`01-${"MM-yyyy"}`);
  //const [page, setPage] = useState(1)
  const [byMonth, setByMonth] = useState(selectedMonth);
  const [records, setRecords] = useState([]);
  //const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  //const [openPopup, setOpenPopup] = useState(false)
  //const [transactionId, setTransactionId] = useState(0)
  // const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  // const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

  const [uptimeArray, setUptimeArray] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const [filterObject, setFilterObject] = useState({
    ocpp_cbid: null,
    from_date: null,
    to_date: null,
    export: false,
    range: false,
    with_max: false
  });

  const onShowHideFilter = () => {    
    setShowFilter((toggle) => !toggle);
    if (showFilter) {
      setFilterObject({
        ocpp_cbid: null,
        from_date: null,
        to_date: null,
        export: false,
        range: false,
        with_max: false
      }); 
    }
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
    //setOpenPopup(false);
  };

  const onFilterSubmit = (values) => {
    //setPage(1);
    let updatedValues = {
      ...values,
      ocpp_cbid: values.ocpp_cbid ? values.ocpp_cbid : null,      
      from_date: values.from_date ? DateTime.fromJSDate(values.from_date).toFormat("dd-MM-yyyy") : null,
      to_date: values.to_date ? DateTime.fromJSDate(values.to_date).toFormat("dd-MM-yyyy") : null,
      //range: !showFilter ? false : values.from_date && values.to_date ? true : false,
      range: values.from_date && values.to_date ? true : false,
      date: byMonth,
      with_max: values.max_uptime ? values.max_uptime : false
    };
    //getAll(fetchContext, byMonth, updatedValues);
    setFilterObject(updatedValues);
  };

  const headCells = [
    { id: "", label: "" },
    // { id: 'site_name', label: 'Site Name' },
    // { id: 'amount', label: 'Amount' },
    // { id: 'energy', label: 'Energy (KWH)' },
    // { id: 'charging_duration', label: 'Average Charging Duration (Minutes)' },
    // { id: 'transactions', label: 'Number of Sessions' },
    // { id: '', label: '' },
    //{ id: '', label: '' },
  ];

  const {
    TblContainer,
    //TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, byMonth, filterObject) => {
    let curStr = DateTime.now();
    let dateParam;
    let selMonthStr = byMonth.split("-");
    let selMonth = Number(selMonthStr[1]);
    if (selMonth === curStr.month) {
      dateParam = curStr.toFormat("dd-MM-yyyy");
    } else {
      dateParam = byMonth;
    }

    try {
      const { data } = await fetchContext.authAxios.post(`/get-up-time-report`, {
        //"date":byMonth,
        ...filterObject,
        date: dateParam,
        export: true,
        //range: false,        
      });
      //let res = Object.values(data.data);
      setRecords(data.data);
      let removeNegative = data?.data?.map((item) => {
        if (item?.uptimes < 0) {
          return { ...item, uptimes: 0 };
        } else {
          return item;
        }
      });

      // const totalMinutes = getMinutesInMonth(dateParam);
      setUptimeArray(removeNegative);
      // if(data.data.total && data.data.total > 20) {
      //   let count = Math.ceil(data.data.total / 20);
      //   setCounts(count);
      // } else {
      //   setCounts(1);
      // }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getAll(fetchContext, byMonth, filterObject);
  }, [fetchContext, byMonth, filterObject]);

  const changeSelectOptionHandler = (event) => {
    let report_month = event.target.value;
    setLoaded(false);
    // if (showFilter){
    //   onShowHideFilter();
    // }    
    setShowFilter(false);
    setFilterObject({
      ocpp_cbid: null,
      from_date: null,
      to_date: null,
      export: false,
      range: false,
      with_max: false
    });
    setByMonth(report_month);
    //setSelected(event.target.value);
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>Uptime Report</div>
          
          <div className={classes.filterContainer}>
            {/* <div className={classes.iconAlignment}>
              <div onClick={onShowHideFilter}>
                <div className={classes.filterIcon}>
                  {!showFilter ? (                    
                    <TTip title="Filter">
                      <FilterAltIcon />
                    </TTip>
                  ) : (
                    <TTip title="Remove Filter">
                      <FilterAltOffIcon />
                    </TTip>
                  )}
                </div>
              </div>
            </div> */}
            
            <div className={classes.datePicker}>
              <div className={classes.newButton}>
                <MonthRangeSelect
                  label="Filter by Month"
                  name="report_month"
                  value={byMonth}
                  //error={errors.charger_type}
                  required
                  onChange={changeSelectOptionHandler}
                />
              </div>
            </div>
            {/* <div className={classes.exportIcon}>
            <UpTimeReportExport filterObject={filterObject} byMonth={byMonth} />
          </div> */}
          </div>
        </div>
        {showFilter && (
          <UpTimeFilter
            //setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideFilter}
          />
        )}
        <TblContainer>
          {/* <TblHead /> */}
          {loaded ? (
            <TableBody>
              <TableRow>
                <TableCell>
                  <ResponsiveContainer width={1160} height={370} style={{margin:'auto'}}>
                    <BarChart
                      // width={560}
                      // height={272}
                      data={
                        uptimeArray?.length > 0
                          ? uptimeArray
                          : [
                              {
                                charger_id: "",
                                uptimes: 0,
                              },
                            ]
                      }
                      margin={{
                        top: 0,
                        right: 0,
                        left: -11,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="charger_id" />
                      <YAxis domain={[0, 100]} tickCount={11} />
                      <Tooltip />
                      <Bar type="monotone" dataKey="uptimes" name="Up Time (%)" fill="#a11011" />
                      <Legend />
                      {/* <Bar type="monotone" dataKey="revenue" stackId="a" fill="#4d32de"  />
                        <Bar type={cardinal} dataKey="average" stackId="a" fill="#fb8840"  /> */}

                      <br />
                      <br />
                    </BarChart>
                  </ResponsiveContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>

        <TblContainer>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tblCellInside}>#</TableCell>
              <TableCell className={classes.tblCellInside}>Charger Name</TableCell>
              <TableCell className={classes.tblCellInside}>Uptime %</TableCell>
            </TableRow>
          </TableHead>
          {records ? (
            <TableBody>
              {records.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.charger_id}</TableCell>
                  <TableCell>{Number(item.uptimes) < 0 ? 0 : item.uptimes ? Number(item.uptimes).toFixed(2) : 0} %</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        
      </Paper>
    </>
  );
};

export default UpTimeReport;
