import React from 'react';
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    //fill: '#000',
  },
  icon: {
    fill: '#000',
  },
  inputLabel: {
    //fontFamily: "Aldrich",
    //fontSize: "4vh",
    alignSelf: "center"
  }
}));

const Select = (props) => {
  const classes = useStyles();
  const { name, label, value = '', fullWidth = true, error = null, onChange, options, required, style } = props;

  // const stringValue = useMemo(() => {
  //   if (null === value || undefined === value) {
  //     return '';
  //   }
  //   const valueAsString = String(value);
  //   return valueAsString in options ? valueAsString : '';
  // }, [ value, options ]);

  return (
    <>
      <FormControl variant="standard" required={required} fullWidth={fullWidth} className={classes.formControl}
        style={{ width: '100%' }}

        {...(error && { error: true })}>
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
        <MuiSelect
          variant="standard"
          className={classes.select}
          // inputProps={{
          //   classes: {
          //       icon: classes.icon,
          //   },
          // }}
          //className="MuiTypography-root MuiTypography-h4 MuiTypography-displayInline"
          label={label}
          name={name}
          value={value}
          //onBlur={onBlur} 
          required={required}
          style={style}
          onChange={onChange}>
          {/* <MenuItem value="">None</MenuItem> */}

          {
            options.map(
              item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
            )
          }

        </MuiSelect>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  )
}

export default Select;