import { Paper, Switch, makeStyles } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import React, { useContext, useEffect, useState } from "react";
// import authen from "../assets/images/header/authen.svg";
// import charger from "../assets/images/header/charger.svg";
// import chargergroup from "../assets/images/header/chargergroup.svg";
// import dash from "../assets/images/header/dash.svg";
// import price from "../assets/images/header/price.svg";
// import reports from "../assets/images/header/reports.svg";
// import sites from "../assets/images/header/sites.svg";
// import user from "../assets/images/header/user.svg";
// import logo from "../assets/images/logo-dash.png";
// import mobileLogo from "../assets/images/logo.png";
import Notification from "../components/Notification";
import Popup from "../components/Popup";
//import { CurrencyExchange, Dashboard, LocalActivity, TableRows, ChargingStation, History, Settings, Group } from '@mui/icons-material';
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, NavLink, useHistory } from "react-router-dom";
import { ChStStatusContext } from "../App/App";
import { AuthContext } from "../context/AuthContext";
import { FetchContext } from "../context/FetchContext";
import ChangePasswordForm from "../pages/Signin/ChangePasswordForm";
import { useIsDesktop } from "../context/DisplayContext";
import MessageStatus from "../pages/Signin/MessageStatus";
//import MenuIcon  from '../components/MenuIcon';

const authen = "/assets/authen.svg";
const charger = "/assets/charger.svg";
const chargergroup = "/assets/chargergroup.svg";
const dash = "/assets/dash.svg";
const price = "/assets/price.svg";
const reports = "/assets/reports.svg";
const sites = "/assets/sites.svg";
const user = "/assets/user.svg";
const logo = "/assets/logo-dash.png";
const mobileLogo = "/assets/logo.png";

const useStyles = makeStyles({
  stLink: {
    textDecoration: "none",
    color: "inherit",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: "inherit",
      background: "#fff",
      backgroundColor: "#fff",
    },
  },
  paperStyle: {
    backgroundColor: "#ffffff",
    color: "#ffffff",
  },
});

const Header = () => {
  const auth = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const { setFlState } = useContext(ChStStatusContext);
  const classes = useStyles();
  const history = useHistory();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupmessage, setOpenPopupmessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { forHeaderOnly } = useIsDesktop();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [recordForEdit, setRecordForEdit] = useState(null);

  const signOut = () => {
    // fetchContext.authAxios.post(`logout`).then((res) => {
      auth.logout();
      window.location.reload();
      history.push("/");
    // });
  };
  const onClose = () => {
    setIsOpen(!isOpen);
  };
  const goToChSt = (fStatus) => {
    setFlState(fStatus);
  };
  const goToSites = () => {
    history.push("/sites");
    onClose();
  };
  const goToDashboard = () => {
    history.push("/dashboard");
    onClose();
    setIsOpen(!isOpen);
  };
  const goToEnergyGraph = () => {
    history.push("/energy-graph");
    onClose();
  };
  const goToRevenueGraph = () => {
    history.push("/revenue-graph");
    onClose();
  };
  const goToSessionsGraph = () => {
    history.push("/sessions-graph");
    onClose();
  };
  const goToLoadSharing = () => {
    history.push("/load-sharing");
    onClose();
  };
  const goToChargingStation = () => {
    history.push("/charging-stations");
    onClose();
  };
  const goToChargerGroup = () => {
    history.push("/charger-groups");
    onClose();
  };
  const goToUsers = () => {
    history.push("/users");
    onClose();
  };
  const goToChangeConfiguration = () => {
    history.push("/change-configuration");
    onClose();
  };
  const goToSiteGroups = () => {
    history.push("/site-accounts");
    onClose();
  };
  const goToReportsChargerGroups = () => {
    history.push("/reports-charger-groups");
    onClose();
  };
  const gotosessions = () => {
    history.push("/sessions");
    onClose();
  };
  const goToReportsSites = () => {
    history.push("/reports-sites");
    onClose();
  };
  const goToReportsDisplacements = () => {
    history.push("/reports-displacements");
    onClose();
  };
  const goToReportsGreenhouseGas = () => {
    history.push("/reports-greenhousegas");
    onClose();
  };
  const goToPrices = () => {
    history.push("/prices");
    onClose();
  };
  const goToTaxRates = () => {
    history.push("/tax-rates");
    onClose();
  };
  const goToReportUpTime = () => {
    history.push("/report-uptime");
    onClose();
  };
  const goToSustainability = () => {
    history.push("/sustainability");
    onClose();
  };
  const goToFaultReport = () => {
    history.push("/fault-report");
    onClose();
  };
  const goToLoadSharingSingleStack = () => {
    history.push("/load-sharing-single");
    onClose();
  };
  const goToFirmwareUpload = () => {
    history.push("/firmware-upload");
    onClose();
  };
  const goToManufacturer = () => {
    history.push("/manufacturer");
    onClose();
  };
  const openInPopup = (item) => {
    setRecordForEdit(item);
    onClose();
    setOpenPopup(true);
  };
  const closeForm = () => {
    setOpenPopup(false);
    setOpenPopupmessage(false);
  };
  const handleSubmit = (values) => {
    fetchContext.authAxios
      .post("/change-password", values)
      .then(({ data }) => {
        if (data?.status) {
          // getAll(fetchContext, page);
          // setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Password changed successfully",
            type: "success",
          });
        } else {
          // if (data?.errors?.name) {
          setNotify({
            isOpen: true,
            message: `Password can't be changed as: ${data?.data}`,
            type: "error",
          });
          // }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Password can't be created as: ${err}`,
          type: "error",
        });
      });
  };
  const addOrEdit = (siteGroup, resetForm) => {
    handleSubmit(siteGroup);
    // if (siteGroup.id === 0)
    //     handleSubmit(siteGroup);
    // else
    //     handleEdit(siteGroup.id, siteGroup);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // setNotify({
    //     isOpen: true,
    //     message: 'Submitted Successfully',
    //     type: 'success'
    // })
  };
  const goInvalidTagReport = () => {
    history.push("/invalid-tag-report");
  };
  const goRevenueReport = () => {
    history.push("/revenue-report");
  };
  const handleToggle = () => {
    if (!forHeaderOnly) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      {/* {[false, 'sm', 'md', 'lg', 'xl', 'xxl'].map((expand) => ( */}
      <Paper className={classes.paperStyle}>
        <Navbar>
          <Container fluid>
            <Navbar.Brand component={Link} to="/dashboard">
              <img src={logo} alt="Rede Logo" className="weblogo" />
              <img src={mobileLogo} alt="Rede Logo" className="logomobile" />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <NavDropdown
                  title={
                    <span
                      style={{
                        background: "#fff",
                        textDecoration: "none",
                        color: "#a31112",
                      }}
                    >
                      {" "}
                      Signed in as: {fetchContext.userName}{" "}
                      <PowerSettingsNewIcon />
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      openInPopup("3");
                    }}
                  >
                    Change Password
                  </NavDropdown.Item>
                  {fetchContext.isAdmin && (
                    <NavDropdown.Item
                      onClick={() => {
                        setOpenPopupmessage(true);
                      }}
                    >
                      {/* <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /> */}
                      Message Setting
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={signOut}>Log Out</NavDropdown.Item>
                </NavDropdown>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bgColor"
          variant="light"
          expanded={!forHeaderOnly ? isOpen : null}
        >
          <Container fluid>
            <Navbar.Toggle
              aria-controls="responsive-navar-nav"
              onClick={handleToggle}
            />
            <Navbar.Collapse id="responsive-navar-nav">
              <Nav className="me-auto">
                {/* <NavLink activeClassName="navbar__link--active" className="text-uppercase navbar__link" to="/dashboard"><MenuIcon />&nbsp;Dashboard</NavLink> */}
                {/* <NavLink activeClassName="navbar__link--active" className="text-uppercase navbar__link" to="/dashboard"><img src={dash} alt="Dashboard" className="menuicon" />&nbsp;Dashboard</NavLink> */}
                {fetchContext.isAdmin ? (
                  <NavDropdown
                    className="text-uppercase"
                    style={{ borderRight: "1px solid #b35d5d" }}
                    title={
                      <span>
                        <img src={dash} alt="Sites" className="menuicon" />
                        &nbsp;Dashboard
                      </span>
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={goToDashboard}
                    >
                      {" "}
                      Dashboard{" "}
                    </NavDropdown.Item>
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToEnergyGraph}
                      >
                        {" "}
                        Energy Graph
                      </NavDropdown.Item>
                    )}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToRevenueGraph}
                      >
                        Revenue Graph{" "}
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={goToSessionsGraph}
                    >
                      {" "}
                      Sessions Graph{" "}
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavDropdown
                    className="text-uppercase"
                    style={{ borderRight: "1px solid #b35d5d" }}
                    title={
                      <span>
                        <img src={dash} alt="Sites" className="menuicon" />
                        &nbsp;Dashboard
                      </span>
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={goToDashboard}
                    >
                      {" "}
                      Dashboard{" "}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={goToRevenueGraph}
                    >
                      Revenue Graph{" "}
                    </NavDropdown.Item>
                  </NavDropdown>

                  // <NavLink activeClassName="navbar__link--active" className="text-uppercase navbar__link" to="/dashboard" > <img src={dash} alt="Sites" className="menuicon" /> &nbsp;Dashboard </NavLink>
                )}
                <NavLink
                  activeClassName="navbar__link--active"
                  className="text-uppercase navbar__link"
                  to="/sites"
                  onClick={() => onClose()}
                >
                  <img src={sites} alt="Charger Groups" className="menuicon" />{" "}
                  Sites
                </NavLink>

                {/* <NavDropdown className="text-uppercase" style={{ borderRight: "1px solid #b35d5d" }} title={<span> <img src={sites} alt="Sites" className="menuicon" /> &nbsp;Sites  </span>} id="basic-nav-dropdown">
                  <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: "none", color: "#a31112" }} onClick={goToSiteGroups} >Site Accounts </NavDropdown.Item>
                  <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: "none", color: "#a31112" }} onClick={goToSites} > Sites </NavDropdown.Item>
                   <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: 'none', color: '#a31112' }} onClick={goToChangeConfiguration}>Change Configuration</NavDropdown.Item> 
                   {fetchContext.isAdmin && <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: 'none', color: '#a31112' }} onClick={goToLoadSharing}>Load Sharing</NavDropdown.Item>}
                </NavDropdown> */}
                <NavDropdown
                  className="text-uppercase"
                  style={{ borderRight: "1px solid #b35d5d" }}
                  title={
                    <span>
                      {" "}
                      <img
                        src={chargergroup}
                        alt="Sites"
                        className="menuicon"
                      />{" "}
                      &nbsp;Groups{" "}
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    activeClassName="navbar__link--active"
                    style={{ textDecoration: "none", color: "#a31112" }}
                    onClick={goToChargerGroup}
                  >
                    Charger&nbsp;Groups{" "}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    activeClassName="navbar__link--active"
                    style={{ textDecoration: "none", color: "#a31112" }}
                    onClick={goToSiteGroups}
                  >
                    Site Accounts{" "}
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <NavLink activeClassName="navbar__link--active" className="text-uppercase navbar__link" to="/charger-groups" ><img src={chargergroup} alt="Charger Groups" className="menuicon" /> &nbsp;Charger&nbsp;Groups </NavLink> */}
                {fetchContext.isAdmin ? (
                  <NavDropdown
                    className="text-uppercase"
                    style={{ borderRight: "1px solid #b35d5d" }}
                    title={
                      <span>
                        <img
                          src={charger}
                          alt="Charging Stations"
                          className="menuicon"
                        />
                        &nbsp;Chargers{" "}
                      </span>
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={goToChargingStation}
                    >
                      Charging&nbsp;Stations{" "}
                    </NavDropdown.Item>
                    {/* <img src={charger} alt="Charging Stations" className="menuicon"/> */}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToLoadSharingSingleStack}
                      >
                        {" "}
                        Single Load Management{" "}
                      </NavDropdown.Item>
                    )}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToLoadSharing}
                      >
                        {" "}
                        Multi Load Management
                      </NavDropdown.Item>
                    )}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToFirmwareUpload}
                      >
                        {" "}
                        Upload Firmware
                      </NavDropdown.Item>
                    )}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToManufacturer}
                      >
                        Manufacturer
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                ) : (
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="text-uppercase navbar__link"
                    to="/charging-stations"
                    onClick={goToChSt("fsn")}
                  >
                    {" "}
                    <img
                      src={charger}
                      alt="Charging Stations"
                      className="menuicon"
                    />{" "}
                    &nbsp;Charging&nbsp;Stations{" "}
                  </NavLink>
                )}
                {/* <NavLink activeClassName="navbar__link--active" className="text-uppercase navbar__link" to="/charging-stations" onClick={goToChSt("fsn")} > <img src={charger} alt="Charging Stations" className="menuicon" /> &nbsp;Charging&nbsp;Stations</NavLink> */}
                {/* <NavLink activeClassName="navbar__link--active" className="text-uppercase navbar__link" to="/sessions" > <img src={sessions} alt="Sessions" className="menuicon" /> &nbsp;Sessions </NavLink> */}
                {
                  <NavDropdown
                    className="text-uppercase"
                    style={{ borderRight: "1px solid #b35d5d" }}
                    title={
                      <span>
                        {" "}
                        <img
                          src={reports}
                          alt="Reports"
                          className="menuicon"
                        />{" "}
                        &nbsp;Reports{" "}
                      </span>
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={gotosessions}
                    >
                      {" "}
                      Sessions
                    </NavDropdown.Item>
                    {!fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToReportsSites}
                      >
                        {" "}
                        Charger Usage Report{" "}
                      </NavDropdown.Item>
                    )}
                    {/* <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: "none", color: "#a31112" }} onClick={goToReportsSites} > Charger Usage Report </NavDropdown.Item> */}

                    {/* {fetchContext.isAdmin && ( */}
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToReportsChargerGroups}
                      >
                        {" "}
                        Sessions by Charger Group{" "}
                      </NavDropdown.Item>
                    {/* )} */}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToReportsSites}
                      >
                        {" "}
                        Sessions by Site{" "}
                      </NavDropdown.Item>
                    )}
                    {/* {fetchContext.isAdmin && (<NavDropdown.Item ctiveClassName="navbar__link--active" style={{ textDecoration: "none", color: "#a31112" }} onClick={goToReportsDisplacements} >Petroleum Displacement Report </NavDropdown.Item>)}
                  {fetchContext.isAdmin && (<NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: "none", color: "#a31112" }} onClick={goToReportsGreenhouseGas} > Greenhouse Gas Report </NavDropdown.Item>)} */}
                    {/* <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: 'none', color: '#a31112' }} onClick={goToReportUpTime}>Uptime Report for Chargers</NavDropdown.Item>
                  <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: 'none', color: '#a31112' }} onClick={goToFaultReport}>Fault Report</NavDropdown.Item>
                  <NavDropdown.Item activeClassName="navbar__link--active" style={{ textDecoration: 'none', color: '#a31112' }} onClick={goInvalidTagReport}>Invalid RFID Tag Report</NavDropdown.Item> */}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToReportUpTime}
                      >
                        {" "}
                        Uptime Report for Chargers{" "}
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={goToSustainability}
                    >
                      {" "}
                      Sustainability{" "}
                    </NavDropdown.Item>

                    {(fetchContext.isAdmin || fetchContext.isTechnician) && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goToFaultReport}
                      >
                        {" "}
                        Fault Report{" "}
                      </NavDropdown.Item>
                    )}
                    {(fetchContext.isAdmin || fetchContext.isTechnician) && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goInvalidTagReport}
                      >
                        {" "}
                        Invalid RFID Tag Report{" "}
                      </NavDropdown.Item>
                    )}
                    {fetchContext.isAdmin && (
                      <NavDropdown.Item
                        activeClassName="navbar__link--active"
                        style={{ textDecoration: "none", color: "#a31112" }}
                        onClick={goRevenueReport}
                      >
                        {" "}
                        Revenue Report{" "}
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                }
                {!fetchContext.isView && (
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="text-uppercase navbar__link"
                    to="/rfid"
                  >
                    {" "}
                    <img
                      src={authen}
                      alt="Authenticate"
                      className="menuicon"
                      onClick={() => onClose()}
                    />{" "}
                    &nbsp;Authenticate{" "}
                  </NavLink>
                )}
                <NavDropdown
                  className="text-uppercase"
                  style={{ borderRight: "1px solid #b35d5d" }}
                  title={
                    <span>
                      {" "}
                      <img src={price} alt="Prices" className="menuicon" />{" "}
                      &nbsp;Prices{" "}
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    activeClassName="navbar__link--active"
                    style={{ textDecoration: "none", color: "#a31112" }}
                    onClick={goToPrices}
                  >
                    {" "}
                    Prices{" "}
                  </NavDropdown.Item>
                  {fetchContext.isAdmin && (
                    <NavDropdown.Item
                      activeClassName="navbar__link--active"
                      style={{ textDecoration: "none", color: "#a31112" }}
                      onClick={goToTaxRates}
                    >
                      {" "}
                      Tax Rates{" "}
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                {fetchContext.isAdmin && (
                  <NavLink
                    activeClassName="navbar__link--active"
                    className="text-uppercase navbar__link"
                    to="/users"
                  >
                    {" "}
                    <img
                      src={user}
                      alt="Users"
                      className="menuicon"
                      onClick={() => onClose()}
                    />{" "}
                    &nbsp;Users{" "}
                  </NavLink>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Paper>
      <Popup
        title="Change Password"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ChangePasswordForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          closeForm={closeForm}
          isCurrentPasswordRequire={true}
        />
      </Popup>
      <Popup
        title="Send Message"
        openPopup={openPopupmessage}
        setOpenPopup={setOpenPopupmessage}
      >
        <MessageStatus setOpenPopup={setOpenPopupmessage} />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      {/* ))} */}
    </>
  );
};

export default Header;
