import React from 'react';
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        width: "20vw"
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    select: {
        //fill: '#000',
    },
    icon: {
        fill: '#000',
    },
    inputLabel: {
        //fontFamily: "Aldrich",
        //fontSize: "4vh",
        alignSelf: "center"
    }
}));

const SearchWithSelect = (props) => {
    const classes = useStyles();
    const { name, label, value = '', disabled = false, fullWidth = true, error = null, onChange, options, required = false } = props;

    return (
        <>
            {/* <FormControl variant="standard" required={required} disabled={disabled} fullWidth={fullWidth} className={classes.formControl}
                style={{ width: "97.5%" }}

                {...(error && { error: true })}> */}
                <Autocomplete
                    variant="standard"
                    className={classes.select}
                    label={label}
                    id={name}
                    name={name}
                    options={options}
                    value={value}
                    required={required}
                    disabled={disabled}
                    onChange={(event, newValue) => {
                        onChange(newValue || "")
                    }}
                    renderInput={(params) =>
                        <TextField {...params} label={label} required={required} />
                    }
                    getOptionLabel={(option) => option.label || ""}
                    getOptionValue={(option) => option.id || ""}
                >
                    {/* {
            options.map(
              item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
            )

            <Autocomplete
                    variant="standard"
                    className="custom-autocomplete"
                    id={name}
                    name={name}
                    options={options}
                    value={value}
                    required={required}
                    disabled={disabled}
                    onChange={(event, newValue) => onChange(newValue || "")}
                    renderInput={(params) => (
                        <TextField {...params} label={label} />
                    )}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                />

          } */}

                </Autocomplete>
                {error && <FormHelperText>{error}</FormHelperText>}
            {/* </FormControl> */}
        </>
    )
}

export default SearchWithSelect;