import React, { useContext, useEffect, useState } from "react";
//import { FetchContext } from '../../context/FetchContext';
import { Table, TableCell, TableRow } from "@material-ui/core";
import { DateTime } from "luxon";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TYPE_1, TYPE_2, TYPE_3 } from "../Reports/RevenueReport/RevenueType";
import { FetchContext } from "../../context/FetchContext";

const Revenue = ({ transactions, start, end }) => {
  const [data, setData] = useState();
  const [maxValue, setMaxValue] = useState(60);
  const fetchContext = useContext(FetchContext);

  const sitePayout = (item, licensing_fee, final_amount, processing_fee) => {
    if(final_amount < 0) {
      return 0;
    }
    // let fees = item?.processing_fee;
    if (item?.agreement_type) {
      switch (item?.agreement_type) {
        case TYPE_1:
          return licensing_fee;
        case TYPE_2:
          return final_amount;
        case TYPE_3:
          return item?.amount - item?.tax_amount - processing_fee;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (!transactions) {
      return;
    }

    // const dailyData = {
    //   datetime: DateTime,
    //   count: 0,
    //   usage: 0
    // };

    const dailyData = {};

    let datetime = start.startOf("day");
    while (datetime < end.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        amount: 0,
        site_payout: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }

    transactions.forEach((transaction) => {
      // if (transaction.amount < 1) {
      //   return;
      // }

      // if (!transaction.meter_end || !transaction.meter_start) {
      //   return;
      // }

      const key = DateTime.fromSQL(transaction.graph_date).startOf("day").toISO();
      if (key && dailyData[key]) {
        // if (transaction?.connector?.charging_stations?.site?.processing_fee?.length > 0) {
        // let processing_fee = transaction?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => {
        let processing_fee = 0;
        if (transaction?.processing_fee_type === "percentage") {
          processing_fee = transaction?.amount <= 0 ? 0 : (transaction?.amount - transaction?.tax_amount) * (transaction?.processing_fee / 100);
        }
        // }, 0);

        // let licensing_fee = transaction?.licensing_fee_energy * (transaction?.meter_diff ? transaction?.meter_diff / 1000 : 0);
        let licensing_fee = transaction?.amount <= 0 ? 0 : transaction?.licensing_fee_energy * (transaction?.meter_diff ? transaction?.meter_diff / 1000 : 0);
        let final_amount = transaction?.amount <= 0 ? 0 : transaction?.amount - transaction?.tax_amount - processing_fee - licensing_fee;
        // }
        dailyData[key].count++;
        dailyData[key].amount += transaction.amount;
        dailyData[key].site_payout += transaction?.amount <= 0 ? 0 : sitePayout(transaction, licensing_fee, final_amount, processing_fee);
      }
    });

    setData(
      Object.values(dailyData).reduce((acc, { count, datetime, amount, site_payout }) => {
        const dt = DateTime.fromMillis(datetime.ts);
        const month = dt.monthLong;

        return [
          ...acc,
          datetime !== undefined && {
            amount: amount.toFixed(2),
            average: count ? getRound(amount, count) : 0,
            site_payout: site_payout.toFixed(2),
            // average_site_payout: count ? getRound(site_payout, count) : 0,
            month,
            date: datetime.day,
          },
        ];
      }, [])
    );
  }, [end, start, transactions]);

  const getRound = (value1, value2) => {
    let response = 0;
    if (value2 !== undefined) response = (value1 / value2).toFixed(2);
    else response = value1 / value2;

    return response;
  };

  const getTotal = (data) => {
    let allTotal = 0;
    // let allAverage = 0;
    let number_of_days = data?.length;
    let final_average = 0;
    if (data) {
      for (const d of data) {
        // let field = d.amount;
        let field = fetchContext?.isAdmin ? d.amount : d.site_payout;
        if (Number(field) > 0) {
          allTotal = allTotal + parseFloat(field);
        }
      }
    }
    final_average = allTotal > 0 ? (allTotal / number_of_days).toFixed(2) : "0.00";
    return {
      allTotal: allTotal.toFixed(2),
      // allAverage: allAverage.toFixed(2)
      average: final_average,
    };
  };

  useEffect(() => {
    if (data) {
      const maxUsage = data.reduce((max, obj) => {
        const usage = parseFloat(obj.amount);
        return usage > max ? usage : max;
      }, 0);

      const nextMultiple = Math.ceil(maxUsage / 10) * 10;
      setMaxValue(nextMultiple);
    }
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light p-2">
          <p className="py-0 my-0">{`${payload[0].payload.month} ${label}`}</p>

          {fetchContext?.isAdmin ? (
            <p className="py-0 my-0 text-danger">{`Amount : $ ${payload[0].value}`}</p>
          ) : (
            <p className="py-0 my-0 text-danger">{`Site Payout : $ ${payload[0]?.payload?.site_payout}`}</p>
          )}
          {/* <p className="py-0 my-0 text-danger">{`Amount : $ ${payload[0].value}`}</p> */}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div class="card shadow mb-4" style={{ borderRadius: "10px" }}>
        <div class="card-body">
          <div class="card-header row" style={{ background: "#fff" }}>
            <div class="col-md-6">
              <strong class="card-title">Revenue </strong> <span class="card-title">(USD)</span>{" "}
            </div>
            {/* <div class="col-md-3 totalkwh">&nbsp;</div> */}
            <div class="col-md-3 totalkwh">
              Total <span>{getTotal(data).allTotal}</span>{" "}
            </div>
            <div class="col-md-3 totalkwh">
              {" "}
              Average <span>{getTotal(data).average}</span>
            </div>
          </div>
          <div class="clearfix"></div>
          <div className="d-flex flex-row">
            <div
              style={{
                writingMode: "vertical-rl",
                transform: "rotate(-180deg)",
                width: "fit-content",
              }}
              className="text-vertical text-center"
            >
              {/* Amount (USD) */}
              {fetchContext?.isAdmin ? "Amount (USD)" : "Site Payout (USD)"}
            </div>
            <Table>
              <TableRow>
                <TableCell>
                  <div className="responsiveCharts">
                    <ResponsiveContainer>
                      <BarChart
                        // width={560}
                        // height={400}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          left: -9,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="date" />
                        <YAxis domain={[0, maxValue]} tickCount={11} />
                        {/* <YAxis /> */}
                        {/* <Bar type="monotone" dataKey="revenue" stackId="a" fill="#4d32de"  />
                        <Bar type={cardinal} dataKey="average" stackId="a" fill="#fb8840"  /> */}
                        <Tooltip content={<CustomTooltip />} />
                        {/* <Legend wrapperStyle={{ position: 'relative' }} /> */}

                        {/* <Bar type="monotone" dataKey={"amount"} fill="#a11011" /> */}
                        <Bar type="monotone" dataKey={fetchContext?.isAdmin ? "amount" : "site_payout"} fill="#a11011" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </TableCell>
              </TableRow>
            </Table>
          </div>
          <div className="text-center pt-0 mt-0">
            <span style={{ fontSize: "24px", lineHeight: 1.3, color: "rgb(161, 16, 17)" }}>&#9632;</span> <span>Date</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Revenue;
